import { default as aboutUOwrFSw3P2Meta } from "/www/wwwroot/fts_frontend/pages/about.vue?macro=true";
import { default as indexn2jCIWjP10Meta } from "/www/wwwroot/fts_frontend/pages/dashboard/index.vue?macro=true";
import { default as messagesYSO9c2n8mkMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/messages.vue?macro=true";
import { default as settingsYDR0khBLUlMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/settings.vue?macro=true";
import { default as usersS8MYs9Ds6mMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/users.vue?macro=true";
import { default as indexpVUC3FwqPcMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/website-data/index.vue?macro=true";
import { default as mediaUQElWoYoRXMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/website-data/media.vue?macro=true";
import { default as _91id_936CsH4uW10pMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/website-data/pages/[id].vue?macro=true";
import { default as indexMlj6F9ZStgMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/website-data/pages/index.vue?macro=true";
import { default as servicesR5pb4KGmeiMeta } from "/www/wwwroot/fts_frontend/pages/dashboard/website-data/services.vue?macro=true";
import { default as team662fCFBkw0Meta } from "/www/wwwroot/fts_frontend/pages/dashboard/website-data/team.vue?macro=true";
import { default as indexDAcvaxSUETMeta } from "/www/wwwroot/fts_frontend/pages/index.vue?macro=true";
import { default as loginTUTsTNlUkfMeta } from "/www/wwwroot/fts_frontend/pages/login.vue?macro=true";
import { default as indexQzsvLD2yQtMeta } from "/www/wwwroot/fts_frontend/pages/services/index.vue?macro=true";
import { default as why_45ftsAIit1V9O1gMeta } from "/www/wwwroot/fts_frontend/pages/why-fts.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/www/wwwroot/fts_frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexn2jCIWjP10Meta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages",
    path: "/dashboard/messages",
    meta: messagesYSO9c2n8mkMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/messages.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: settingsYDR0khBLUlMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "/dashboard/users",
    meta: usersS8MYs9Ds6mMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/users.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data",
    path: "/dashboard/website-data",
    meta: indexpVUC3FwqPcMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/website-data/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-media",
    path: "/dashboard/website-data/media",
    meta: mediaUQElWoYoRXMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/website-data/media.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-pages-id",
    path: "/dashboard/website-data/pages/:id()",
    meta: _91id_936CsH4uW10pMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/website-data/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-pages",
    path: "/dashboard/website-data/pages",
    meta: indexMlj6F9ZStgMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/website-data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-services",
    path: "/dashboard/website-data/services",
    meta: servicesR5pb4KGmeiMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/website-data/services.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-team",
    path: "/dashboard/website-data/team",
    meta: team662fCFBkw0Meta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/dashboard/website-data/team.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/www/wwwroot/fts_frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginTUTsTNlUkfMeta || {},
    component: () => import("/www/wwwroot/fts_frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/www/wwwroot/fts_frontend/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "why-fts",
    path: "/why-fts",
    component: () => import("/www/wwwroot/fts_frontend/pages/why-fts.vue").then(m => m.default || m)
  }
]